/**
 * Remove FT widget if present
 *
 * @returns {undefined}
 */
export function useRemoveFormitable() {
	onMounted(() => {
		const formitableHTML = document.getElementsByClassName('ft-widget-b2')[0];

		if (formitableHTML) {
			formitableHTML.remove();
		}
	});
}
